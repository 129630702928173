import { Section } from '@components/styledComponents/index';
import styled from 'styled-components';

export const OurService = styled(Section)`
  padding: 3rem 1.6rem 0;
  @media (min-width: 1200px) {
    flex-direction: row;
    margin: 10.9rem 15rem 13.2rem;
    > div:nth-child(2) {
      margin-left: 5.7rem;
    }
  }
`;
