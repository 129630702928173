import React from 'react';
import Layout from '@layouts/default';
import SEO from '@layouts/seo';
import Services from '@components/pages/services/index';

const ServicesPage = props => {
  return (
    <Layout {...props}>
      <SEO title="Services" />
      <Services />
    </Layout>
  );
};

export default ServicesPage;
