import React from 'react';
import ServicesSlider from '@components/services-slider/index';
import {
  Section,
  Subtitle,
  Title,
  Paragraph,
  Container,

} from '@components/styledComponents/index';
import {
  OurService,
} from './styles';

const Services = () => {
  return (
    <>
      <Container>
        <OurService>
          <div>
            <Subtitle>Our services</Subtitle>
            <Title>Digital Transformation</Title>
          </div>
          <div>
            <Paragraph>
              We advise clients on IT strategy, business case development and
              cost rationalization, and then help clients deliver on that
              strategy, from governance to quality assurance and logistics. We
              tailor customized solutions for clients through our CIO-on-tap
              verticals: CIO-on-tap advisory and CIO-on-tap execution. Each
              vertical is comprised of modules, each module can be available
              separately for you, the customer, to mix and match according to
              your needs.
            </Paragraph>
          </div>
        </OurService>
      </Container>
      <Container>
        <Section padding={'1.7rem 1.4rem 3.5rem'} deskPadding={'0'}>
          <ServicesSlider />
        </Section>
      </Container>
    </>
  );
};

export default Services;
